/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Container className="text-page">
      <Row>
        <Col>
        <h2><strong>Privacy Policy</strong></h2>
            <p>This privacy policy is for the Nobs App which is a product of Nobs App &ndash; any reference to &ldquo;Nobs App&rdquo; in the following articles is in reference to Nobs App.&nbsp;</p>
            <p>We do not share your private information with other parties. Your confidentiality is key to us and is according to YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)</p>
            <h5><strong>1. INFORMATION WE COLLECT</strong></h5>
            <p>We collect the following types of information. Information you provide us directly:</p>
            <ul>
                <li>When you register for an account:</li>
                <ul>
                    <li>Via Email: Your Full Name, password, and email address.</li>
                </ul>
                <li>Private Information: All content shared on Nobs App is private to the public. We do not make content public in any way.</li>
                <li>Communications between you and Nobs App. For example, we may send you Service-related emails (e.g., account verification, changes/updates to features of the Service, technical and security notices). Note that you may not opt out of Service-related e-mails.</li>
                <li>Your email and password are private and not available to the public.</li>
            </ul>
            <p>Technical information we collect:</p>
            <ul>
                <li>Additionally, any use of Nobs App&rsquo;s website and/or software may result in the collection of technical information such as your computer&rsquo;s IP address, mobile device identifier, meta data, operating system, browser name/version, the referring web page, requested page; such information is used to help us understand the overall usage pattern of our website and software.</li>
                <li>We also use third-party analytics tools to help us measure how you use Nobs App. These tools collect information sent by your device or our Service, including how you like to use our software and website which can help us in improving our Service.</li>
                <li>Nobs App may invite you to provide information through feedback on features on our website or software; we may send out e-mail inviting feedback or offering products and services, or we may ask you for information concerning support of our products or services. Your response to these communications may require you to submit personal information and opinions.</li>
            </ul>
            <h5><strong>2. HOW WE USE YOUR INFORMATION</strong></h5>
            <p>We may use the information that we receive to:</p>
            <ul>
                <li>Help you efficiently access your information after you sign in</li>
                <li>Remember information so you will not have to re-enter it during your visit or the next time you visit the Service;</li>
                <li>Provide personalized content and information to you and others, which could include online ads or other forms of marketing</li>
                <li>Provide, improve, test, and monitor the effectiveness of our Service</li>
                <li>Develop and test new products and features</li>
                <li>Monitor metrics such as total number of visitors, traffic, and demographic patterns</li>
                <li>Diagnose or fix technology problems</li>
                <li>Automatically update the Nobs App application on your device</li>
            </ul>
            <h5><strong>3. SHARING OF YOUR INFORMATION</strong></h5>
            <p>We will not rent or sell your information to third parties outside Nobs App without your consent, except as noted in this Policy. Parties with whom we may share your information:</p>
            <ul>
                <li>We may remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.</li>
                <li>We may be required to disclose your information without your prior consent by law for the following reasons:</li>
                <ul>
                    <li>Comply with the law or legal process</li>
                    <li>Protect and defend our rights and property, or the rights and property of a third party</li>
                    <li>Protect us against misuse or unauthorized use of any of Nobs App&rsquo;s products, software, services, or other proprietary materials</li>
                    <li>Prevent death or imminent bodily harm. prevent and address fraud and other illegal activity</li>
                </ul>
            </ul>
            <h5><strong>4. HOW WE STORE YOUR INFORMATION</strong></h5>
            <ul>
                <li>Storage and Processing:</li>
                <ul>
                    <li>We use commercially reasonable precautions to help keep your personal information safe and stored securely. However, Nobs App cannot ensure the security of any information you transmit to Nobs App or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed.</li>
                    <li>Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails between you and Nobs App, at all times. Your privacy settings may also be affected by changes the social media services you connect to Nobs App make to their services. We are not responsible for the functionality, privacy, or security measures of any other organization.</li>
                    <li>Following termination or deactivation of your account, Nobs App, its Affiliates, or its Service Providers may retain information (including your profile information) and User Content for a commercially reasonable time for backup, archival, and/or audit purposes.</li>
                    <li>Email our support to know about deleting your account support@nobsapp.zendesk.com</li>
                </ul>
            </ul>
            <h5><strong>5. CHILDREN&rsquo;S PRIVACY</strong></h5>
            <p>Nobs App does not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register for the Service. The Service and its content are not directed at children under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us.</p>
            <h5><strong>6. OTHER WEBSITES AND SERVICES</strong></h5>
            <p>We are not responsible for the practices employed by any websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another website or service, our Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website/software, or are accessed through our web/in-app browser are subject to that third party&rsquo;s own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access your User Content. If you are using a third-party website or service and you allow them to access your User Content you do so at your own risk.</p>
            <h5><strong>7. YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)</strong></h5>
            <p>If you are a resident of the EEA, you have the following data protection rights:</p>
            <ul>
                <li>If you wish to access, correct, update, or request deletion of your personal information, you can do so at any time by support@nobsapp.zendesk.com</li>
                <li>In addition, you can object to the processing of your personal information, ask us to restrict the processing of your personal information or request portability of your personal information. Again, you can exercise these rights by emailing support@nobsapp.zendesk.com</li>
                <li>You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the &ldquo;unpurchase&rdquo; or &ldquo;opt-out&rdquo; link in the marketing emails we send you. To opt-out of other forms of marketing, please contact us by emailing support@nobsapp.zendesk.com</li>
                <li>Similarly, if we have collected and process your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</li>
                <li>You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.</li>
            </ul>
            <p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>
            <h5><strong>8. OTHER WEBSITES AND SERVICES</strong></h5>
            <p>If you have any questions about this Privacy Policy or the Service, please find the appropriate support channel in the Nobs App settings or contact us at support@nobsapp.zendesk.com</p>
            <h5><strong>9. CHANGES TO OUR PRIVACY POLICY</strong></h5>
            <p>Nobs App may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of Nobs App or the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.</p>

        </Col>
      </Row>
    </Container>
    </Layout>
);

export default PrivacyPage;
